* {
  box-sizing: border-box;
}
.App {
  text-align: center;
}
.main-wrapper {
  width: 100%;
  overflow: hidden;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0 auto;
  max-width: 1800px;
}
p,
h4,
h5 {
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  /* background-color: #0c1618; */
  background-color: #012027;
}
h1 {
  font-size: 9rem;
  line-height: 10rem;
}
h2 {
  font-size: 3.8rem;
  line-height: 4.5rem;
}
h3 {
  font-size: 3.3rem;
  line-height: 3.5rem;
}
h4 {
  font-size: 3rem;
  line-height: 3.3rem;
}
h5 {
  font-size: 2.2rem;
  line-height: 2.5rem;
}
p,
a,
li,
span,
div {
  font-size: 2rem;
  line-height: 2.2rem;
}

@media (max-width: 500px) {
  .main-wrapper {
    padding: 0;
  }
  html {
    font-size: 60%;
  }
  h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
}
